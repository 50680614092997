import GLightbox from "glightbox";
const galleryItems = document.querySelectorAll(".js-gallery-list");
import Flickity from "flickity";

if (galleryItems && galleryItems.length > 0) {
  new GLightbox();

  galleryItems.forEach((galleryItem) => {
    if (galleryItem.classList.contains("gallery__images--slider")) {
      const flickity = new Flickity(galleryItem, {
        cellAlign: "left",
        contain: true,
        adaptiveHeight: true,
      });
    }
  });
}

const coworkerItems = document.querySelectorAll(".js-coworker-item");

if (coworkerItems && coworkerItems.length > 0) {
  const mediaQuery = window.matchMedia("(max-width: 992px)");

  const handleCoworkerDetails = (mediaQuery) => {
    coworkerItems.forEach((item) => {
      const coworkerExpanderButton = item.querySelector(
        ".js-coworker-detail-expander"
      );
      const coworkerDetail = item.querySelector(".js-coworker-detail");
      const coworkerDetailExpanderBlock = item.querySelector(
        ".js-coworker-detail-expander-block"
      );

      if (
        coworkerExpanderButton &&
        coworkerDetail &&
        coworkerDetailExpanderBlock
      ) {
        const innerDetail = coworkerDetail.querySelector(
          ".js-coworker-detail--inner"
        );

        // Check the height of the coworker detail and only show the expander button if the height is less than 178px
        if (innerDetail) {
          // Only apply height checks below 768px
          if (innerDetail.scrollHeight <= 178) {
            coworkerDetailExpanderBlock.style.setProperty(
              "--coworker-detail-expander-block-display",
              "none"
            );
            coworkerDetail.style.setProperty(
              "--coworker-detail-height",
              "auto"
            );
          } else {
            coworkerDetailExpanderBlock.style.setProperty(
              "--coworker-detail-expander-block-display",
              "flex"
            );
            coworkerDetail.style.setProperty(
              "--coworker-detail-height",
              "178px"
            );
          }
        }

        // Add event listener to the coworker detail expander button
        coworkerExpanderButton.addEventListener("click", () => {
          if (coworkerExpanderButton.getAttribute("aria-expanded") === "true") {
            coworkerDetail.style.setProperty(
              "--coworker-detail-height",
              "178px"
            );
            coworkerExpanderButton.setAttribute("aria-expanded", "false");
          } else {
            coworkerDetail.style.setProperty(
              "--coworker-detail-height",
              innerDetail.scrollHeight + 64 + "px"
            );
            coworkerExpanderButton.setAttribute("aria-expanded", "true");
          }
        });
      }
    });
  };

  // Initial check
  let triggered = false;
  if (mediaQuery.matches) {
    handleCoworkerDetails(mediaQuery);
    triggered = true;
  }
  mediaQuery.addEventListener("change", (e) => {
    if (e.matches && !triggered) {
      handleCoworkerDetails(e);
      triggered = true;
    }
  });
}
